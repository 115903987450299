import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tiles from './tiles';

const SectionTiles = ({ className, ...props }) => (
  <section className={classNames('section-tiles layout-section', className)}>
    <Tiles {...props} />
  </section>
);

SectionTiles.propTypes = {
  className: PropTypes.string,
};

export default SectionTiles;
