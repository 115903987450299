import React, { Component } from 'react';
import PropTypes from 'prop-types';
import codeSplitLoad from 'Webapp/shared/utils/code-split-load';

// Utils
import { VIEWPORT_TYPES } from 'Utils/viewport-types';

// Components
import Button, { StyleVariations } from 'Webapp/shared/app/components/button';
import SectionTile from 'Webapp/shared/app/containers/section-tile';
import LoadMore, {
  STYLE_VARIATIONS as LOAD_MORE_STYLE_VARIATIONS,
} from './load-more';
import CollapsableAnchorSection from './collapsable-anchor-section';

import withT from 'ComponentLibrary/hocs/withT';

import connector from 'Utils/connector';
import connectAmp from 'Webapp/shared/app/connectors/connectAmp';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';

const SmartMagazineTile = codeSplitLoad('SmartMagazineTile');
const StoryboardTile = codeSplitLoad('StoryboardTile');
const CampaignTile = codeSplitLoad('CampaignTile');

class Tiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };
    this.getDisplayCount = this.getDisplayCount.bind(this);
    this.handleSeeMoreClick = this.handleSeeMoreClick.bind(this);
    this.renderTile = this.renderTile.bind(this);
  }

  getDisplayCount() {
    const multiplier = this.props.highDisplayCount ? 3 : 1;

    switch (this.props.viewportType) {
      case VIEWPORT_TYPES.PHONE:
        return multiplier * 4;
      case VIEWPORT_TYPES.TABLET: {
        return multiplier * 10;
      }
      case VIEWPORT_TYPES.TABLET_LANDSCAPE: {
        return multiplier * 12;
      }
      case VIEWPORT_TYPES.DESKTOP:
      default: {
        return multiplier * 14;
      }
    }
  }

  handleSeeMoreClick() {
    this.setState({ isExpanded: true });
  }

  renderTile(section) {
    const { isOwnProfile } = this.props;
    if (section.isSmartMagazine) {
      return <SmartMagazineTile section={section} />;
    } else if (section.isStoryboard) {
      return (
        <StoryboardTile
          section={section.section || section}
          isOwnProfile={isOwnProfile}
        />
      );
    } else if (section.isCampaign) {
      return (
        <CampaignTile
          section={section.section || section}
          isOwnProfile={isOwnProfile}
        />
      );
    }
    return <SectionTile section={section} isOwnProfile={isOwnProfile} />;
  }

  render() {
    const {
      anchorId,
      title,
      titleElement,
      sections,
      createTileComponent,
      isAmp,
      emptyMessage,
      loadNext,
      loadingNext,
      collapsable,
      collapsed,
      t,
    } = this.props;
    if (!sections.length && !createTileComponent && !emptyMessage) {
      return null;
    }
    const { isExpanded } = this.state;

    // Display count shows 1 less magazine if authenticated, to account for create magazine
    const displayCount = createTileComponent
      ? this.getDisplayCount() - 1
      : this.getDisplayCount();
    const displayTiles =
      isExpanded || isAmp ? sections : sections.slice(0, displayCount);
    const renderShowMore =
      !isExpanded && !isAmp && sections.length > displayCount;

    const headerElement = titleElement ? (
      titleElement
    ) : (
      <h2 className="layout-section__header section-tiles__header ui-text--title">
        {title}
      </h2>
    );

    const content = (
      <React.Fragment>
        <ul className="section-tiles__list">
          {createTileComponent && <li>{createTileComponent}</li>}
          {displayTiles.map((section) => (
            <li key={section.remoteid || section.userid || section.id}>
              {this.renderTile(section)}
            </li>
          ))}
        </ul>
        {renderShowMore && (
          <Button
            name={`profile-${anchorId}__see-more`}
            label={t('see_more')}
            styleVariation={StyleVariations.SECONDARY}
            onClick={this.handleSeeMoreClick}
          />
        )}
        {!renderShowMore && loadNext && (
          <LoadMore
            infinite={false}
            loadNext={loadNext}
            isLoading={loadingNext}
            styleVariation={LOAD_MORE_STYLE_VARIATIONS.SECTION_TILES}
          />
        )}
        {displayTiles.length === 0 && emptyMessage && (
          <p className="ui-text--supporting">{emptyMessage}</p>
        )}
      </React.Fragment>
    );

    if (collapsable) {
      return (
        <CollapsableAnchorSection
          collapsed={collapsed}
          header={headerElement}
          anchorId={anchorId}
        >
          {content}
        </CollapsableAnchorSection>
      );
    }

    return (
      <React.Fragment>
        <span id={anchorId} className="page-anchor" />
        {(title || titleElement) && headerElement}
        {content}
      </React.Fragment>
    );
  }
}

Tiles.propTypes = {
  sections: PropTypes.array.isRequired,
  anchorId: PropTypes.string,
  isAmp: PropTypes.bool,
  createTileComponent: PropTypes.node,
  viewportType: PropTypes.oneOf(Object.values(VIEWPORT_TYPES)),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  titleElement: PropTypes.node,
  emptyMessage: PropTypes.string,
  isOwnProfile: PropTypes.bool,
  highDisplayCount: PropTypes.bool,
  loadNext: PropTypes.func,
  loadingNext: PropTypes.bool,
  collapsable: PropTypes.bool,
  collapsed: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

Tiles.defaultProps = {
  isAmp: false,
  createTileComponent: null,
  title: 'Magazines',
  emptyMessage: null,
  isOwnProfile: false,
  highDisplayCount: false,
  loadNext: null,
  loadingNext: false,
  collapsable: true,
  collapsed: false,
};

export default connector(connectAmp, connectResponsive)(withT(Tiles));
