import React from 'react';
import PropTypes from 'prop-types';
import { ICON_COLORS } from 'Style/colors';

// Components
import CaretIcon, { CARET_DIRECTIONS } from 'ComponentLibrary/icons/caret';

class CollapsableSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isCollapsed: this.props.collapsed,
    };

    this.toggleCollapsed = this.toggleCollapsed.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { collapsed } = this.props;
    if (prevProps.collapsed !== collapsed) {
      this.setState({ isCollapsed: collapsed });
    }
  }

  toggleCollapsed() {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }

  render() {
    const { className, anchorId, header, children } = this.props;
    const { isCollapsed } = this.state;

    return (
      <div className={className}>
        {anchorId && <span id={anchorId} className="page-anchor" />}
        <div
          className="collapsable-section__header"
          onClick={this.toggleCollapsed}
        >
          <CaretIcon
            className="collapsable-section__caret"
            color={ICON_COLORS.caret}
            direction={
              isCollapsed ? CARET_DIRECTIONS.right : CARET_DIRECTIONS.down
            }
          />
          {header}
        </div>
        {!isCollapsed && children}
      </div>
    );
  }
}

CollapsableSection.propTypes = {
  className: PropTypes.string,
  collapsed: PropTypes.bool,
  header: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  anchorId: PropTypes.string,
};

CollapsableSection.defaultProps = {
  className: null,
  collapsed: false,
  anchorId: null,
};

export default CollapsableSection;
