import React from 'react';
import PropTypes from 'prop-types';
import getWindow from 'Utils/get-window';
// Components
import CollapsableSection from './collapsable-section';

class CollapsableAnchorSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openForAnchor: this.anchorMatches(),
    };

    this.anchorMatches = this.anchorMatches.bind(this);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.openForAnchor) {
      return;
    }

    if (this.anchorMatches()) {
      this.setState({ openForAnchor: true });
    }
  }

  anchorMatches() {
    return (
      getWindow() &&
      getWindow().location &&
      getWindow().location.hash === `#${this.props.anchorId}`
    );
  }

  render() {
    const { collapsed } = this.props;
    const collapsableSectionProps = Object.assign({}, this.props, {
      collapsed: !this.state.openForAnchor && collapsed,
    });

    return <CollapsableSection {...collapsableSectionProps} />;
  }
}

CollapsableAnchorSection.propTypes = {
  collapsed: PropTypes.bool,
  anchorId: PropTypes.string,
};

CollapsableAnchorSection.defaultProps = {
  collapsed: false,
  anchorId: null,
};

export default CollapsableAnchorSection;
